import React from 'react';

const PwShow = () => {

    return (
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 16C5.96977 16 2.9471 13.3161 1.1335 11.1484C-0.377834 9.29032 -0.377834 6.70968 1.1335 4.85161C2.9471 2.58065 5.96977 0 10 0C14.0302 0 17.0529 2.68387 18.8665 4.85161C20.3778 6.70968 20.3778 9.29032 18.8665 11.1484C17.0529 13.3161 14.0302 16 10 16ZM10 1.54839C6.47355 1.54839 3.8539 3.81935 2.24181 5.78065C1.23426 7.01935 1.23426 8.77419 2.24181 10.1161C3.8539 12.0774 6.47355 14.4516 10 14.4516C13.5264 14.4516 16.1461 12.0774 17.7582 10.1161C18.7657 8.87742 18.7657 7.12258 17.7582 5.78065C16.1461 3.81935 13.5264 1.54839 10 1.54839Z" fill="black" />
            <path d="M10 11.871C7.88413 11.871 6.17129 10.1161 6.17129 7.94839C6.17129 5.78065 7.88413 4.02581 10 4.02581C12.1159 4.02581 13.8287 5.78065 13.8287 7.94839C13.8287 10.1161 12.1159 11.871 10 11.871ZM10 5.67742C8.79093 5.67742 7.68262 6.70968 7.68262 8.05161C7.68262 9.29032 8.69018 10.4258 10 10.4258C11.2091 10.4258 12.3174 9.39355 12.3174 8.05161C12.3174 6.70968 11.2091 5.67742 10 5.67742Z" fill="black" />
        </svg>
    );
};

export default PwShow;