import React from 'react';

const MainLogo = ({ session }) => {
  const handleLogoClick = () => {
    window.location.href = '/';
    window.location.reload();
  };

  return (
    <a className='mainLogo' href="/" onClick={handleLogoClick}>
      {window.innerWidth < 768 && session ? (
        // Smaller logo when screen is < 768px and session is true
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M24.6486 21.969L33.3512 13.4007L40 20.0498L20.0015 40L0 20.0545L6.64884 13.4054L15.23 21.969V0H24.6486V21.969Z" fill="#FF3D00"/>
        </svg>
      ) : (
        // Larger logo for other cases
        <svg width="93" height="40" viewBox="0 0 93 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M70.7268 28.0727L76.4842 22.4043L80.8828 26.803L67.6523 40.0012L67.6529 39.9644L67.6523 40.0018L54.4199 26.8062L58.8186 22.4075L64.4957 28.0727V13.5391H70.7268V28.0727Z" fill="#FF3D00"/>
          <path d="M7.55489 28.2789C6.05316 28.2789 4.89797 28.0594 4.08935 27.6204C3.28072 27.1814 2.71468 26.5461 2.39123 25.7144C2.09088 24.8595 1.94071 23.8314 1.94071 22.63V11.7482H0V8.04007H1.94071V1.94071H7.65886V8.04007H10.5006V11.7482H7.65886V22.0755C7.65886 22.6531 7.82059 23.069 8.14404 23.3231C8.49059 23.5773 8.91801 23.7044 9.42629 23.7044C9.72664 23.7044 10.0039 23.6928 10.258 23.6697C10.5122 23.6235 10.7432 23.5888 10.9511 23.5657V28.0016C10.6508 28.0478 10.1772 28.1056 9.53026 28.1749C8.88335 28.2442 8.2249 28.2789 7.55489 28.2789Z" fill="black"/>
          <path d="M13.4666 28.0709V0H19.0808V9.8075C19.8201 9.06819 20.5941 8.5368 21.4027 8.21335C22.2114 7.8668 23.0778 7.69352 24.0019 7.69352C24.8105 7.69352 25.4805 7.90145 26.0119 8.31732C26.5664 8.71008 26.9823 9.26457 27.2595 9.98078C27.5368 10.6739 27.6754 11.471 27.6754 12.372V28.0709H22.0612V13.1691C22.0612 12.6839 21.9803 12.3142 21.8186 12.0601C21.6569 11.806 21.3565 11.6789 20.9176 11.6789C20.6865 11.6789 20.4093 11.7482 20.0858 11.8868C19.7624 12.0023 19.4274 12.1756 19.0808 12.4067V28.0709H13.4666Z" fill="black"/>
          <path d="M31.1336 28.0709V8.04007H36.7825V11.367C37.4294 10.2118 38.0763 9.33388 38.7232 8.73318C39.3701 8.10938 40.1556 7.79748 41.0797 7.79748C41.2646 7.79748 41.4263 7.80904 41.5649 7.83214C41.7035 7.83214 41.8306 7.85524 41.9461 7.90145V13.5156C41.692 13.4001 41.4032 13.3077 41.0797 13.2384C40.7794 13.146 40.4559 13.0998 40.1094 13.0998C39.4625 13.0998 38.8618 13.2731 38.3073 13.6196C37.7528 13.9662 37.2445 14.4398 36.7825 15.0405V28.0709H31.1336Z" fill="black"/>
          <path d="M44.8853 33.8931V29.977C45.6016 29.977 46.1907 29.9308 46.6528 29.8384C47.1379 29.746 47.4961 29.5727 47.7271 29.3185C47.9812 29.0644 48.1083 28.7178 48.1083 28.2789C48.1083 27.8861 48.039 27.4125 47.9004 26.858C47.7617 26.2804 47.5885 25.6335 47.3805 24.9173L42.6674 8.04007H47.9697L50.4995 19.615L52.4056 8.04007H57.7425L53.1333 28.5561C52.833 29.9423 52.3594 31.0167 51.7125 31.7791C51.0656 32.5646 50.2685 33.1075 49.3212 33.4079C48.3971 33.7313 47.3459 33.8931 46.1676 33.8931H44.8853Z" fill="black"/>
          <path d="M60.1075 28.0709V0H65.7217V28.0709H60.1075Z" fill="black"/>
          <path d="M69.6174 28.0709V0H75.2316V28.0709H69.6174Z" fill="black"/>
          <path d="M85.4693 28.4175C84.0369 28.4175 82.7893 28.1749 81.7266 27.6897C80.6869 27.2046 79.8783 26.4768 79.3007 25.5064C78.7462 24.5361 78.4689 23.3231 78.4689 21.8676V14.2434C78.4689 12.7879 78.7462 11.5749 79.3007 10.6046C79.8783 9.63423 80.6869 8.90646 81.7266 8.42128C82.7893 7.93611 84.0369 7.69352 85.4693 7.69352C86.9018 7.69352 88.1378 7.93611 89.1775 8.42128C90.2403 8.90646 91.0604 9.63423 91.638 10.6046C92.2156 11.5749 92.5044 12.7879 92.5044 14.2434V21.8676C92.5044 23.3231 92.2156 24.5361 91.638 25.5064C91.0604 26.4768 90.2403 27.2046 89.1775 27.6897C88.1378 28.1749 86.9018 28.4175 85.4693 28.4175ZM85.504 24.6747C85.8737 24.6747 86.1625 24.5823 86.3704 24.3975C86.5783 24.1895 86.7169 23.9123 86.7863 23.5657C86.8787 23.1961 86.9249 22.7571 86.9249 22.2488V13.8622C86.9249 13.3539 86.8787 12.9265 86.7863 12.5799C86.7169 12.2103 86.5783 11.933 86.3704 11.7482C86.1625 11.5403 85.8737 11.4363 85.504 11.4363C85.1343 11.4363 84.8456 11.5403 84.6376 11.7482C84.4297 11.933 84.2795 12.2103 84.1871 12.5799C84.0947 12.9265 84.0485 13.3539 84.0485 13.8622V22.2488C84.0485 22.7571 84.0947 23.1961 84.1871 23.5657C84.2795 23.9123 84.4297 24.1895 84.6376 24.3975C84.8456 24.5823 85.1343 24.6747 85.504 24.6747Z" fill="black"/>
        </svg>
      )}
    </a>
  );
};

export default MainLogo;
