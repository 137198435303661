import React, { useState } from "react";

const CouponPopup = ({ userID, triggerClass }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [twoOResponse, settwoOResponse] = useState(false);
    const [couponCode, setCouponCode] = useState("");
    const [responseMessage, setResponseMessage] = useState("");

    const handleOpenPopup = () => {
        setIsPopupOpen(true);
        setResponseMessage(""); // Clear any previous response
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        settwoOResponse(false);
        setCouponCode(""); // Clear input field
        setResponseMessage(""); // Clear any response
    };

    const handleConfirm = async () => {
        try {
            const response = await fetch("/addCoupon", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ coupon: couponCode, userID: userID }),
            });

            if (!response.ok) {
                // If the response status is not OK (200-299), handle it as an error
                const errorData = await response.json();
                setResponseMessage(errorData.message || "An unexpected error occurred.");
                return;
            } else if (response.status === 201) {
                settwoOResponse(true);
            }

            const data = await response.json();
            setResponseMessage(data.message);
        } catch (error) {
            // Catch fetch or JSON parsing errors
            console.error("Error applying coupon:", error);
            setResponseMessage("Error applying coupon. Please try again later.");
        }
    };

    return (
        <div>
            <p className={triggerClass} onClick={handleOpenPopup}>Got a Coupon?</p>

            {isPopupOpen && (
                <div className="popup-overlay">
                    <div className="popup">
                        {!twoOResponse ? (
                            <div className="formElements">
                                <h3 className="noMarginP" style={{ color: 'black' }}>
                                    Coupon Time – Let’s Do This!
                                </h3>
                                {!responseMessage ? (
                                    <p className="noMarginP">Pop your coupon in here and watch the magic happen.</p>
                                ) : (
                                    <p className="response-message noMarginP redText">{responseMessage}</p>
                                )}
                                <input
                                    className="mainInput inputSmall"
                                    type="text"
                                    placeholder="Enter coupon code"
                                    value={couponCode}
                                    onChange={(e) => setCouponCode(e.target.value)}
                                    required
                                />
                                <div className="popup-buttons">
                                    <button className="btnBasic greyBtn" onClick={handleClosePopup}>
                                        Cancel
                                    </button>
                                    <button className="btnBasic" onClick={handleConfirm}>
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="formElements">
                                <p className="noMarginP">Success! Your coupon is in, and the savings will be rolling.</p>
                                <button className="btnBasic" onClick={handleClosePopup}>
                                    Close 
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CouponPopup;
