import React from 'react';

const Converse = () => {
  return (
    <svg width="45" height="50" viewBox="0 0 45 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.325 22.475V14.325L7.6875 20.925L0 18.375L4.85 24.975L0.0875002 31.575L7.7 29.0625L12.3375 35.6625V27.5125L20.1125 24.9625L12.325 22.475ZM44.425 25.025L27.375 43.75H12.7375L29.8 25.025L12.7375 6.25H27.3775L44.425 25.025Z" fill="black"/>
    </svg>
    );
}; 

export default Converse;