import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const PriceHistoryChart = ({ history }) => {

    const calculateTimeAgo = (timestamp) => {
        const currentTime = new Date();
        const historyTime = new Date(timestamp);
        const timeDiff = currentTime - historyTime;
        const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));
        const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        if (hoursDiff <= 1) {
            return "1h ago";
        } else if (hoursDiff < 24) {
            return `${hoursDiff}h ago`;
        } else {
            return `${daysDiff}d ago`;
        }
    };

    
    const sortedHistory = history.slice().sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
    const data = sortedHistory.map(item => ({
        time: calculateTimeAgo(item.timestamp),
        price: typeof item.price === 'string' ? parseFloat(item.price.replace(',', '.')) : item.price
    }));
    
    const minPrice = Math.min(...data.map(item => item.price)) * 0.9;  
    const maxPrice = Math.max(...data.map(item => item.price)) * 1.1;  

    return (
        <ResponsiveContainer width="100%" height={200}>
            <LineChart data={data} margin={{ top: 10, bottom: 10, right: 20 }}>
                <CartesianGrid stroke="#FFE5E5" strokeDasharray="5 5"/>
                <XAxis dataKey="time" label={{ value: "Date", position: "insideBottomRight", offset: -10 }} />
                <YAxis domain={[minPrice, maxPrice]} tick={false} label={{ value: "Price", position: "insideTopLeft" }} />
                <Tooltip 
                    contentStyle={{ backgroundColor: 'white', borderRadius: 20, color: 'black', border: 'solid 1px #FFE5E5', padding: '10px 20px' }}
                    formatter={(value) => `${value}`} 
                />
                <Line type="monotone" dataKey="price" stroke="#FF3D00" dot={{ r: 4 }} activeDot={{ r: 8, fill: '#FF3D00' }} />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default PriceHistoryChart;
