import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import NavBar from './NavBar';

const brands = [
  "Amazon",
  "Argos",
  "Asos",
  "Banana Republic",
  "Bed Bath and Beyond",
  "Best Buy",
  /* "Bol", */
  "Converse",
/*   "Dermstore", */
  "Ebay",
  "Etsy",
  "Gap",
  "Home Depot",
  "Ikea",
  "Jcrew",
  "Jordan",
  /* "Kohls", */
  "Lidl (Europe)",
  /* "Lululemon", */
  "Macys",
  "Madewell",
  "Marks and Spencer",
  "Microcenter",
  "Next",
  "Nike",
  "Old Navy",
  "Otto",
  "Poshmark",
  "Reformation",
  /*   "Sam's club", */
  "Shop bop",
  "Spartoo",
  /* "Target", */
  "Ulta",
  "Walmart",
  "Zalando",
  "Zappos",
];

const BrandList = ({ language }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestedBrand, setSuggestedBrand] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showSuggestForm, setShowSuggestForm] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    console.log('Current query params:', params.toString());  // Log the current query params
    if (params.get('suggest') === 'true') {
      console.log('Query param detected: suggest=true');
      setShowSuggestForm(true);
    }
  }, []);

  const filteredBrands = brands.filter((brand) =>
    brand.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("/newStoreSuggestion", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          suggestedBrand,
          email,
        }),
      });

      if (response.ok) {
        setMessage(`Thank you for suggesting ${suggestedBrand}. We'll email you as soon as it is available.`);
        setSuggestedBrand("");
        setEmail("");
        setShowSuggestForm(false);
        setSearchTerm("");
        const url = new URL(window.location);
        url.searchParams.delete('suggest');
        window.history.replaceState({}, '', url);

      } else {
        setMessage("There was an error submitting your suggestion. Please try again.");
      }
    } catch (error) {
      console.error("Error suggesting the brand:", error);
      setMessage("There was an error submitting your suggestion. Please try again.");
    }
  };

  return (
    <>
      {/* Display a message after suggesting a brand */}
      {message &&
        <div className='msgBanner centeredText'>
          <p className='centeredText whiteText noMarginP'>{message}</p>
        </div>
      }
      <div style={message ? { marginTop: "50px" } : {}}>
        <NavBar language={language} />
      </div>


      {!showSuggestForm && (
        <>
          <h1 className='redText centeredText capText'>Monitor Prices from These Top Brands and Stores</h1>
          {/* Search Input */}
          <input
            type="text"
            placeholder="Search for an online store..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className='mainInput'
            style={{
              width: "100%",
              marginBottom: "20px"
            }}
          />

          {/* If brands are found, display them */}
          {filteredBrands.length > 0 ? (
            <ul style={{ listStyle: "none", padding: 0 }}>
              {filteredBrands.map((brand, index) => (
                <li
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px 0",
                    borderBottom: "1px solid #FFE5E5",
                  }}
                >
                  <p className="noMarginP">Price tracker for {brand}</p>
                  <p className="noMarginP">
                    <a className='redLink'
                      href='/#trackersForm'
                      target='_blank'
                    >
                      Track {brand} →
                    </a>
                  </p>
                </li>
              ))}
            </ul>
          ) : (
            <>
              {/* Suggest Form */}
              {!showSuggestForm && (
                <>
                  <p>It looks like this store isn't available yet. Suggest it to us, and we'll prioritize adding it as soon as possible!</p>
                  <button
                    onClick={() => setShowSuggestForm(true)}
                    className='btnBasic btnBig'
                  >
                    Suggest this store
                  </button>
                </>
              )}
            </>
          )}
        </>
      )}

      {/* Show suggest form when showSuggestForm is true */}
      {showSuggestForm && !message && (
        <>
          <h1 className='redText centeredText capText'>Suggest your favorite store</h1>
          <p className='centeredText'>We will send you an email as soon as it will be available</p>
          <form className='formElements formL' onSubmit={handleSubmit} >
            <div>
              <input
                type="text"
                placeholder="Your favorite store name"
                value={suggestedBrand}
                onChange={(e) => setSuggestedBrand(e.target.value)}
                required
                className='mainInput'
                style={{ width: "100%" }}
              />
            </div>
            <div>
              <input
                type="email"
                placeholder="Your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className='mainInput'
                style={{ width: "100%" }}
              />
            </div>
            <button
              type="submit"
              className='btnBasic btnBig'
            >
              Suggest {suggestedBrand}
            </button>
          </form>
        </>
      )}

      <div className="footerHero marginT centeredV">
        <h2 className='redText capText noMarginP marginBB centeredText'>Ready to save money on your favorite items?</h2>
        <button className='btnBasic btnBig btnBlack' onClick={() => window.location.href = "/#trackersForm"}>
          Yeah, Let's do it!
        </button>
      </div>


      <Footer language={language} />
    </>
  );
};

export default BrandList;