import React, { useState } from 'react';
import supabase from './SupabaseClient';
import LogoBig from './icons/LogoBig';
import PWHide from './icons/PwHide';
import PwShow from './icons/PwShow';

function SignIn({ language }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [error, setError] = useState(null);
  const [resetSent, setResetSent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSignIn = async (e) => {
    e.preventDefault();
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) {
      setErrorMessage(error.message);
    }
  };

  const handleResetPassword = async () => {
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(resetEmail, {
        redirectTo: `${window.location.origin}/updatepassword`,
      });

      if (error) {
        setError(error.message);
      } else {
        setResetSent(true);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const texts = {
    en: {
      emailLabel: 'Your Email',
      passwordLabel: 'Your Password',
      forgotPassword: 'You forgot your password?',
      resetPassword: 'Forgot Password?',
      signIn: 'Continue',
      notUser: 'Not a Member Yet?',
      registerNow: 'Register Now!',
      signUp: 'Sign up',
      heading: 'Track Prices, Buy at the Lowest, Save Money!',
      subHeading: 'Get notified when the price changes on your favorite items.',
      bulletOne: '📈 Real-time price tracking',
      bulletTwo: '⏱️ Instant alerts on price change',
      bulletThree: '😏 Track multiple items at once',
      forgotPW: 'Forgot Password?',
      forgotPWSub: 'No worries! Fill in your email and we\'ll send you a link to reset your password',
      resetPW: 'Reset Your Password',
      cancel: 'Cancel',
      PwResetSent: 'We\'ve sent a secure link to reset your password to',
      PwCheckInbox: 'Please check your inbox and follow the instructions to regain access to your account. If you don\'t see the email, be sure to check your spam or junk folder.',
      closeWindow: 'Close this window',
    },
    fr: {
      emailLabel: 'Votre adresse e-mail',
      passwordLabel: 'Votre mot de passe',
      forgotPassword: 'Vous avez oublié votre mot de passe ?',
      resetPassword: 'Mot de passe oublié ?',
      signIn: 'Continuer',
      notUser: 'Pas encore membre ?',
      registerNow: 'Inscrivez-vous maintenant !',
      signUp: 'S’inscrire',
      heading: 'Suivez les prix, achetez au plus bas, économisez de l\'argent !',
      subHeading: 'Recevez une notification lorsque le prix baisse sur vos articles préférés.',
      bulletOne: '📈 Suivi des prix en temps réel',
      bulletTwo: '⏱️ Alertes instantanées sur les baisses de prix',
      bulletThree: '😏 Suivi de plusieurs articles à la fois',
      forgotPW: 'Mot de passe oublié ?',
      forgotPWSub: 'Pas de souci ! Remplissez votre e-mail et nous vous enverrons un lien pour réinitialiser votre mot de passe',
      resetPW: 'Réinitialiser le mot de passe',
      cancel: 'Annuler',
      PwResetSent: 'Nous avons envoyé un lien sécurisé pour réinitialiser votre mot de passe à',
      PwCheckInbox: 'Veuillez vérifier votre boîte de réception et suivre les instructions pour récupérer l\'accès à votre compte. Si vous ne voyez pas l\'e-mail, vérifiez votre dossier de spam ou de courrier indésirable.',
      closeWindow: 'Fermer cette fenêtre',
    },
    es: {
      emailLabel: 'Tu correo electrónico',
      passwordLabel: 'Tu contraseña',
      forgotPassword: '¿Olvidaste tu contraseña?',
      resetPassword: '¿Olvidaste la contraseña?',
      signIn: 'Continuar',
      notUser: '¿No eres miembro aún?',
      registerNow: '¡Regístrate ahora!',
      signUp: 'Regístrate',
      heading: '¡Sigue los precios, compra al más bajo y ahorra dinero!',
      subHeading: 'Recibe notificaciones cuando baje el precio de tus artículos favoritos.',
      bulletOne: '📈 Seguimiento de precios en tiempo real',
      bulletTwo: '⏱️ Alertas instantáneas de bajada de precios',
      bulletThree: '😏 Sigue varios artículos a la vez',
      forgotPW: '¿Olvidaste la contraseña?',
      forgotPWSub: '¡No te preocupes! Ingresa tu correo y te enviaremos un enlace para restablecer tu contraseña',
      resetPW: 'Restablecer contraseña',
      cancel: 'Cancelar',
      PwResetSent: 'Hemos enviado un enlace seguro para restablecer tu contraseña a',
      PwCheckInbox: 'Revisa tu bandeja de entrada y sigue las instrucciones para recuperar el acceso a tu cuenta. Si no ves el correo, revisa tu carpeta de spam o correo no deseado.',
      closeWindow: 'Cerrar esta ventana',
    },
    de: {
      emailLabel: 'Ihre E-Mail-Adresse',
      passwordLabel: 'Ihr Passwort',
      forgotPassword: 'Haben Sie Ihr Passwort vergessen?',
      resetPassword: 'Passwort vergessen?',
      signIn: 'Weiter',
      notUser: 'Noch kein Mitglied?',
      registerNow: 'Jetzt registrieren!',
      signUp: 'Anmelden',
      heading: 'Preise verfolgen, zum niedrigsten Preis kaufen, Geld sparen!',
      subHeading: 'Erhalten Sie Benachrichtigungen, wenn der Preis für Ihre Lieblingsartikel sinkt.',
      bulletOne: '📈 Echtzeit-Preisverfolgung',
      bulletTwo: '⏱️ Sofortige Preisbenachrichtigungen',
      bulletThree: '😏 Mehrere Artikel gleichzeitig verfolgen',
      forgotPW: 'Passwort vergessen?',
      forgotPWSub: 'Kein Problem! Geben Sie Ihre E-Mail-Adresse ein, und wir senden Ihnen einen Link zum Zurücksetzen des Passworts',
      resetPW: 'Passwort zurücksetzen',
      cancel: 'Abbrechen',
      PwResetSent: 'Wir haben einen sicheren Link zum Zurücksetzen Ihres Passworts gesendet an',
      PwCheckInbox: 'Bitte überprüfen Sie Ihr Postfach und folgen Sie den Anweisungen, um wieder Zugang zu Ihrem Konto zu erhalten. Wenn Sie die E-Mail nicht sehen, überprüfen Sie bitte Ihren Spam- oder Junk-Ordner.',
      closeWindow: 'Dieses Fenster schließen',
    },
    lt: {
      emailLabel: 'Jūsų el. paštas',
      passwordLabel: 'Jūsų slaptažodis',
      forgotPassword: 'Pamiršote slaptažodį?',
      resetPassword: 'Pamirštas slaptažodis?',
      signIn: 'Tęsti',
      notUser: 'Dar nesate narys?',
      registerNow: 'Registruokitės dabar!',
      signUp: 'Registruotis',
      heading: 'Sekite kainas, pirkite pigiausiai, taupykite pinigus!',
      subHeading: 'Gaukite pranešimus, kai sumažės jūsų mėgstamų prekių kainos.',
      bulletOne: '📈 Kainų stebėjimas realiu laiku',
      bulletTwo: '⏱️ Momentiniai pranešimai apie kainų sumažėjimą',
      bulletThree: '😏 Vienu metu sekite kelis daiktus',
      forgotPW: 'Pamiršote slaptažodį?',
      forgotPWSub: 'Nesijaudinkite! Įveskite savo el. pašto adresą ir mes atsiųsime jums nuorodą slaptažodžiui atstatyti',
      resetPW: 'Atstatyti slaptažodį',
      cancel: 'Atšaukti',
      PwResetSent: 'Mes išsiuntėme saugią nuorodą slaptažodžiui atstatyti į',
      PwCheckInbox: 'Prašome patikrinti savo el. pašto dėžutę ir vykdyti nurodymus, kad atkurtumėte prieigą prie savo paskyros. Jei nematote laiško, patikrinkite savo šlamšto ar nepageidaujamų laiškų aplanką.',
      closeWindow: 'Uždaryti šį langą',
    }
  };

  const t = texts[language];

  return (
    <div className='onbrdFlex'>
      <div className='formLeft'>
        <div className='marginBB'>
          <LogoBig />
        </div>
        <form className='formElements' onSubmit={handleSignIn}>
          {errorMessage && <p className='noMarginP redText centeredText'>{errorMessage}</p>}
          <input className='mainInput inputSmall' type="email" id="emailInput" value={email} onChange={(e) => setEmail(e.target.value)} placeholder={t.emailLabel} />
          <div className='passwordField'>
            <input className='mainInput inputSmall PwInput' type={showPassword ? "text" : "password"} id="passwordInput" value={password} onChange={(e) => setPassword(e.target.value)} placeholder={t.passwordLabel} />
            <button type="button" onClick={() => setShowPassword(!showPassword)} className="togglePasswordVisibility">
              {showPassword ? <PWHide /> : <PwShow />}
            </button>
          </div>
          <p className='baseLink smallP noMarginP rAligned pntr' onClick={() => setShowPopup(true)}><strong>{t.resetPassword}</strong></p>
          <button className='btnBasic' type="submit">{t.signIn}</button>
          <p className='centeredText'>{t.notUser} <a className='redLink' href="/signup"><strong>{t.registerNow}</strong></a></p>
        </form>
      </div>
      <div className='formRight'>
        <h1 className='capText'>{t.heading}</h1>
        <p className='noMarginP whiteText'>{t.subHeading}</p>
        <ul>
          <li className='marginS'>{t.bulletOne}</li>
          <li className='marginS'>{t.bulletTwo}</li>
          <li className='marginS'>{t.bulletThree}</li>
        </ul>
      </div>
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup">
            {!resetSent && (
              <div className='formElements'>
                <h3 className='noMarginP'>{t.forgotPW}</h3>
                <p className='noMarginP'>{t.forgotPWSub}</p>
                <input className='mainInput inputSmall' type="email" id="resetEmailInput" value={resetEmail} onChange={(e) => setResetEmail(e.target.value)} placeholder={t.emailLabel} />
                {error && <div style={{ color: 'red' }}>{error}</div>}
                <button className='btnBasic' onClick={handleResetPassword}>{t.resetPW}</button>
                <button className='btnBasic greyBtn' onClick={() => setShowPopup(false)}>{t.cancel}</button>
              </div>
            )}
            {resetSent &&
              <div className='formElements'>
                <p className='noMarginP bigP'>
                  {t.PwResetSent} <strong>{resetEmail}.</strong>
                </p>
                <p className='noMarginP'>
                  {t.PwCheckInbox}
                </p>
                <button className='btnBasic' onClick={() => setShowPopup(false)}>{t.closeWindow}</button>
              </div>
            }
          </div>
        </div>
      )}
    </div>
  );
}

export default SignIn;
