import React, { useState, useEffect } from 'react';
import supabase from './SupabaseClient';
import './Plans.css';
import NavBar from './NavBar';
import FAQComponent from './ProductFAQ';
import Footer from './Footer';

const Plans = ({ setShowPlans, productId, activateProduct, activeProductID, setactivateProduct, setActiveProductID, language }) => {
    const [session, setSession] = useState(null);
    const [productData, setProductData] = useState(null);

    useEffect(() => {
        const fetchProductData = async () => {
            if (productId) {
                const response = await fetch(`/getProduct?id=${productId}`);
                const data = await response.json();
                if (data.product) {
                    setProductData(data.product);
                } else {
                    console.log("No product found");
                }
            }
        };

        fetchProductData();
    }, [productId]);

    useEffect(() => {
        const { data: { subscription } } = supabase.auth.onAuthStateChange(
            async (event, session) => {
                setSession(session);
            }
        );

        return () => {
            subscription?.unsubscribe();
        };
    }, []);

    const createCheckoutSession = async (priceId) => {
        try {
            const requestBody = {
                user_email: session.user.email,
                user_id: session.user.id,
                price_id: priceId,
                productId: productId,
            };

            if (productData) {
                requestBody.priceText = productData.priceText;
            } else if (activeProductID) {
                requestBody.activate = "1";
                requestBody.productId = activeProductID;
            }

            const response = await fetch('/createPayment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            if (response.ok) {
                const data = await response.json();
                if (data.redirectUrl) {
                    window.location.href = data.redirectUrl;
                } else {
                    console.error('Failed to create checkout session');
                }
            } else {
                console.error('Failed to create checkout session');
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
        }
    };

    const handleBuyLater = () => {
        setactivateProduct(false);
        setActiveProductID(null);
        setShowPlans(false);
    };

    const handleCancel = async () => {
        const userID = session.user.id;
        try {
            await fetch(`/delete?id=${productId}&userID=${userID}`, {
                method: 'DELETE'
            });
            window.location.href = '/';
        } catch (error) {
            console.error('Error deleting product:', error);
        }
    };

    const texts = {
        en: {
            trackersNeed: 'Trackers for Every Need',
            individualTracker: 'Get one tracker for just 0.99€, or save money picking up extra trackers for everything else on your wishlist.',
            individualOrPack: 'Buy individually or save more with 10 or 100 packs.',
            oneTracker: '1 Tracker',
            oneTrackerDescription: 'Perfect for trying out our tracker',
            oneTrackerPrice: '$0.99',
            oneTrackerSavings: 'Test our service with minimal risk',
            buyOne: 'Buy 1 tracker',
            buyTenTrackers: 'Buy 10 trackers',
            tenTrackerDescription: 'Ideal for tracking multiple items',
            tenTrackerSavings: 'Save 2$ and get 2 free trackers compared to buying individually',
            buyTen: 'Buy 10 trackers',
            buyHundredTrackers: 'Buy 100 trackers',
            hundredTrackerDescription: 'Best value for heavy users',
            hundredTrackerSavings: 'Save 40$ get 40 free trackers compared to buying individually',
            buyHundred: 'Buy 100 trackers',
            buyLater: 'Buy later',
            cancel: 'Cancel',
            readyToSave: 'Ready to save money on your favorite items?',
            buttonText: "Yeah, Let's do it!",
            tenTrackersSavedPrice: "$9.99",
            tenTrackersPrice: "$7.99",
            hundredTrackersSavedPrice: "$99.9",
            hundredTrackersPrice: "$59.99",
        },
        fr: {
            trackersNeed: 'Suivi pour tous les besoins',
            individualTracker: 'Obtenez un tracker pour seulement 0,99 €, ou économisez de l’argent en prenant des trackers supplémentaires pour tout le reste de votre liste de souhaits.',
            individualOrPack: 'Achetez individuellement ou économisez plus avec des packs de 10 ou 100.',
            oneTracker: '1 Tracker',
            oneTrackerDescription: 'Parfait pour essayer notre tracker',
            oneTrackerPrice: '0,99 €',
            oneTrackerSavings: 'Testez notre service avec un risque minimal',
            buyOne: 'Acheter 1 tracker',
            buyTenTrackers: 'Acheter 10 trackers',
            tenTrackerDescription: 'Idéal pour suivre plusieurs articles',
            tenTrackerSavings: 'Économisez 2 € et obtenez 2 trackers gratuits par rapport à un achat individuel',
            buyTen: 'Acheter 10 trackers',
            buyHundredTrackers: 'Acheter 100 trackers',
            hundredTrackerDescription: 'Meilleure valeur pour les utilisateurs intensifs',
            hundredTrackerSavings: 'Économisez 40 € et obtenez 40 trackers gratuits par rapport à un achat individuel',
            buyHundred: 'Acheter 100 trackers',
            buyLater: 'Acheter plus tard',
            cancel: 'Annuler',
            readyToSave: 'Prêt à économiser sur vos articles préférés ?',
            buttonText: 'Oui, Carrément !',
            tenTrackersSavedPrice: "9.99 €",
            tenTrackersPrice: "7.99 €",
            hundredTrackersSavedPrice: "99.9 €",
            hundredTrackersPrice: "59.99 €",
        },
        es: {
            trackersNeed: 'Rastreador para cada necesidad',
            individualTracker: 'Obtén un rastreador por solo 0,99€, o ahorra dinero al adquirir rastreadores adicionales para el resto de tu lista de deseos.',
            individualOrPack: 'Compra individualmente o ahorra más con paquetes de 10 o 100.',
            oneTracker: '1 Rastreador',
            oneTrackerDescription: 'Perfecto para probar nuestro rastreador',
            oneTrackerPrice: '$0,99',
            oneTrackerSavings: 'Pon a prueba nuestro servicio con riesgo mínimo',
            buyOne: 'Comprar 1 rastreador',
            buyTenTrackers: 'Comprar 10 Rastreadores',
            tenTrackerDescription: 'Ideal para rastrear múltiples artículos',
            tenTrackerSavings: 'Ahorra 2$ y obtén 2 rastreadores gratis en comparación con la compra individual',
            buyTen: 'Comprar 10 rastreadores',
            buyHundredTrackers: 'Comprar 100 Rastreadores',
            hundredTrackerDescription: 'Mejor valor para usuarios frecuentes',
            hundredTrackerSavings: 'Ahorra 40$ y obtén 40 rastreadores gratis en comparación con la compra individual',
            buyHundred: 'Comprar 100 rastreadores',
            buyLater: 'Comprar más tarde',
            cancel: 'Cancelar',
            readyToSave: '¿Listo para ahorrar en tus artículos favoritos?',
            buttonText: '¡Sí, hagámoslo!',
            tenTrackersSavedPrice: "$9.99",
            tenTrackersPrice: "$7.99",
            hundredTrackersSavedPrice: "$99.9",
            hundredTrackersPrice: "$59.99",
        },
        de: {
            trackersNeed: 'Tracker für jeden Bedarf',
            individualTracker: 'Holen Sie sich einen Tracker für nur 0,99 €, oder sparen Sie Geld, indem Sie zusätzliche Tracker für alles andere auf Ihrer Wunschliste kaufen.',
            individualOrPack: 'Kaufen Sie einzeln oder sparen Sie mehr mit 10- oder 100er-Packs.',
            oneTracker: '1 Tracker',
            oneTrackerDescription: 'Perfekt, um unseren Tracker auszuprobieren',
            oneTrackerPrice: '0,99€',
            oneTrackerSavings: 'Testen Sie unseren Service mit minimalem Risiko',
            buyOne: '1 Tracker kaufen',
            buyTenTrackers: '10 Trackers kaufen',
            tenTrackerDescription: 'Ideal zum Verfolgen mehrerer Artikel',
            tenTrackerSavings: 'Sparen Sie 2€ und erhalten Sie 2 kostenlose Tracker im Vergleich zum Einzelkauf',
            buyTen: '10 Trackers kaufen',
            buyHundredTrackers: '100 Trackers kaufen',
            hundredTrackerDescription: 'Bestes Preis-Leistungs-Verhältnis für Vielnutzer',
            hundredTrackerSavings: 'Sparen Sie 40€ und erhalten Sie 40 kostenlose Tracker im Vergleich zum Einzelkauf',
            buyHundred: '100 Trackers kaufen',
            buyLater: 'Später kaufen',
            cancel: 'Stornieren',
            readyToSave: 'Bereit, bei Ihren Lieblingsartikeln Geld zu sparen?',
            buttonText: 'Ja, lass es uns tun!',
            tenTrackersSavedPrice: "9.99 €",
            tenTrackersPrice: "7.99 €",
            hundredTrackersSavedPrice: "99.9 €",
            hundredTrackersPrice: "59.99 €",
        },
        lt: {
            trackersNeed: 'Sekimo priemonės visiems poreikiams',
            individualTracker: 'Gaukite vieną sekimo priemonę už tik 0,99 €, arba sutaupykite pinigų pasirinkdami papildomas sekimo priemones visiems kitiems savo norų sąrašo elementams.',
            individualOrPack: 'Pirkti atskirai arba sutaupyti daugiau su 10 ar 100 pakuočių.',
            oneTracker: '1 Sekimo Priemonė',
            oneTrackerDescription: 'Puikiai tinka išbandyti mūsų sekimo priemonę',
            oneTrackerPrice: '0,99€',
            oneTrackerSavings: 'Išbandykite mūsų paslaugą su minimalia rizika',
            buyOne: 'Pirkti 1 sekimo priemonę',
            buyTenTrackers: 'Pirkti 10 Sekimo Priemonių',
            tenTrackerDescription: 'Idealus pasirinkimas, norint stebėti kelis daiktus',
            tenTrackerSavings: 'Sutaupykite 2€ ir gaukite 2 nemokamas sekimo priemones, palyginti su atskiru pirkimu',
            buyTen: 'Pirkti 10 sekimo priemonių',
            buyHundredTrackers: 'Pirkti 100 Sekimo Priemonių',
            hundredTrackerDescription: 'Geriausias pasirinkimas intensyviems vartotojams',
            hundredTrackerSavings: 'Sutaupykite 40€ ir gaukite 40 nemokamų sekimo priemonių, palyginti su atskiru pirkimu',
            buyHundred: 'Pirkti 100 sekimo priemonių',
            buyLater: 'Pirkti vėliau',
            cancel: 'Atšaukti',
            readyToSave: 'Pasiruošę sutaupyti pinigų už savo mėgstamus daiktus?',
            buttonText: 'Taip, pradėkime!',
            tenTrackersSavedPrice: "9.99 €",
            tenTrackersPrice: "7.99 €",
            hundredTrackersSavedPrice: "99.9 €",
            hundredTrackersPrice: "59.99 €",
        },
    };

    const t = texts[language];

    return (
        <div className={session ? 'marginM' : ''}>
            {!session &&
                <NavBar language={language} />
            }

            <h3 className={session ? 'noMarginP' : 'noMarginP marginM'}>{t.trackersNeed}</h3>
            {productId && productData ? (
                <p>{t.individualTracker}</p>
            ) : (
                <p>{t.individualOrPack}</p>
            )}

            <div className="plans-grid marginB">
                <div className="plan-element">
                    <h3 className="noMarginP">{t.oneTracker}</h3>
                    <p className="smallP noMarginP">{t.oneTrackerDescription}</p>
                    <p className="price">{t.oneTrackerPrice}</p>
                    <p className="savings">{t.oneTrackerSavings}</p>
                    {session ? (
                        <button className="btnBasic" onClick={() => createCheckoutSession('price_1QAIn1AoTKLqnO7blosrDqoA')}>{t.buyOne}</button>
                    ) : (
                        <button className="btnBasic" onClick={() => window.location.href = '/signup'}>{t.buyOne}</button>
                    )}
                </div>
                <div className="plan-element">
                    <h3 className="noMarginP">{t.buyTenTrackers}</h3>
                    <p className="smallP noMarginP">{t.tenTrackerDescription}</p>
                    <p className="price"><span className="savedPrice">{t.tenTrackersSavedPrice}</span> {t.tenTrackersPrice}</p>
                    <p className="savings">{t.tenTrackerSavings}</p>
                    {session ? (
                        <button className="btnBasic" onClick={() => createCheckoutSession('price_1QAIntAoTKLqnO7bG04n96T8')}>{t.buyTen}</button>
                    ) : (
                        <button className="btnBasic" onClick={() => window.location.href = '/signup'}>{t.buyOne}</button>
                    )}
                </div>
                <div className="plan-element">
                    <h3 className="noMarginP">{t.buyHundredTrackers}</h3>
                    <p className="smallP noMarginP">{t.hundredTrackerDescription}</p>
                    <p className="price"><span className="savedPrice">{t.hundredTrackersSavedPrice}</span> {t.hundredTrackersPrice}</p>
                    <p className="savings">{t.hundredTrackerSavings}</p>
                    {session ? (
                        <>
                            <button className="btnBasic" onClick={() => createCheckoutSession('price_1QAJ0zAoTKLqnO7bjVU2uzfU')}>{t.buyHundred}</button>
                            {/* <button className="btnBasic" onClick={() => createCheckoutSession('price_1QAIzXAoTKLqnO7bt7fmvAai')}>{t.buyHundred}</button> */} 
                        </>
                    ) : (
                        <button className="btnBasic" onClick={() => window.location.href = '/signup'}>{t.buyOne}</button>
                    )}
                </div>
            </div>

            {session && !productId && !productData ? (
                <button className='btnBasic fullWidth greyBtn' onClick={handleBuyLater}>{t.buyLater}</button>
            ) : (
                session && <button className='btnBasic fullWidth greyBtn' onClick={handleCancel}>{t.cancel}</button>
            )}

            {!session &&
                <>
                    <div className='marginT'>
                        <FAQComponent language={language} />
                        <div className="footerHero marginT centeredV">
                            <h2 className='redText capText noMarginP marginBB centeredText'>{t.readyToSave}</h2>
                            <button className='btnBasic btnBig btnBlack cen' onClick={() => window.location.href = "/#trackersForm"}>
                                {t.buttonText}
                            </button>
                        </div>
                        <Footer language={language} />
                    </div>
                </>
            }
            
        </div>
    );
};

export default Plans;
