import React from 'react';

const PWHide = () => {

    return (
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.9329 2.06708C14.3168 0.894142 12.3262 0 10 0C5.96977 0 2.9471 2.58065 1.1335 4.85161C-0.377834 6.70968 -0.377834 9.29032 1.1335 11.1484C1.9003 12.0649 2.88324 13.0737 4.06708 13.9329L5.1496 12.8504C3.96741 12.0382 2.99472 11.0321 2.24181 10.1161C1.23426 8.77419 1.23426 7.01936 2.24181 5.78065C3.8539 3.81935 6.47355 1.54839 10 1.54839C11.8953 1.54839 13.5286 2.20434 14.8844 3.11564L15.9329 2.06708Z" fill="black" />
            <path d="M12.7655 5.23454C12.0687 4.48947 11.0876 4.02581 10 4.02581C7.88413 4.02581 6.17129 5.78065 6.17129 7.94839C6.17129 9.02845 6.5965 10.006 7.2849 10.7151L8.32438 9.67562C7.92413 9.23979 7.68262 8.65807 7.68262 8.05161C7.68262 6.70968 8.79093 5.67742 10 5.67742C10.6108 5.67742 11.1959 5.94087 11.6254 6.37461L12.7655 5.23454Z" fill="black" />
            <path d="M12.3058 7.8155L13.5766 6.54468C13.7395 6.98044 13.8287 7.45366 13.8287 7.94839C13.8287 10.1161 12.1159 11.871 10 11.871C9.48429 11.871 8.99253 11.7667 8.54367 11.5777L9.71383 10.4075C9.80737 10.4196 9.90285 10.4258 10 10.4258C11.2091 10.4258 12.3174 9.39355 12.3174 8.05161C12.3174 7.97176 12.3135 7.89301 12.3058 7.8155Z" fill="black" />
            <path d="M6.49046 13.6309C7.53499 14.1322 8.70649 14.4516 10 14.4516C13.5264 14.4516 16.1461 12.0774 17.7582 10.1161C18.7657 8.87742 18.7657 7.12258 17.7582 5.78065C17.2852 5.20524 16.7256 4.60318 16.0817 4.03963L17.1046 3.01669C17.7802 3.62312 18.3689 4.2568 18.8665 4.85161C20.3778 6.70968 20.3778 9.29032 18.8665 11.1484C17.0529 13.3161 14.0302 16 10 16C8.26138 16 6.71026 15.5005 5.36284 14.7585L6.49046 13.6309Z" fill="black" />
            <path d="M15.8777 0L17 1L1.99893 16L0.9 14.9777L15.8777 0Z" fill="black" />
        </svg>
    );
};

export default PWHide;