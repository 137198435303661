import React, { useState, useEffect } from 'react';
import ConfirmProduct from './ConfirmationProduct'; 
import { useParams } from 'react-router-dom';
import supabase from './SupabaseClient';
import Plans from './Plans';

const ConfirmedEmail = ({ language }) => {
    const [session, setSession] = useState(null);
    const [userData] = useState(null);
    const { productId } = useParams();
    const [showPlans, setShowPlans] = useState(false);
    const [requestSent, setRequestSent] = useState(false);

    useEffect(() => {
        const handleAuthChange = async (event, session) => {
            if (session) {
                setSession(session);
            }
        };

        const { data: { subscription } } = supabase.auth.onAuthStateChange(handleAuthChange);

        return () => {
            subscription?.unsubscribe();
        };
    }, []);

    useEffect(() => {
        const sendUserRequest = async () => {
            if (session && !requestSent) {
                const payload = { userID: session.user.id, userEmail: session.user.email };
                try {
                    setRequestSent(true);  
                    const response = await fetch('/newUser', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(payload),
                    });
                    console.log('Response:', response);
                } catch (error) {
                    console.error('Error:', error);
                    setRequestSent(false);
                }
            }
        };

        sendUserRequest();
    }, [session, requestSent]);

    const texts = {
        en: {
            thankYouEmail: 'Thank you',
            emailConfirmed: 'for confirming your email.',
            continue: 'Continue to thryllo',
        },
        fr: {
            thankYouEmail: 'Merci',
            emailConfirmed: 'd\'avoir confirmé votre e-mail.',
            continue: 'Continuer',
        },
        es: {
            thankYouEmail: 'Gracias',
            emailConfirmed: 'por confirmar tu correo electrónico.',
            continue: 'Continuar',
        },
        de: {
            thankYouEmail: 'Danke',
            emailConfirmed: 'für die Bestätigung Ihrer E-Mail.',
            continue: 'Fortsetzen',
        },
        lt: {
            thankYouEmail: 'Ačiū',
            emailConfirmed: 'už el. pašto patvirtinimą.',
            continue: 'Tęsti',
        },
    };

    const t = texts[language];


    return (
        <>
            {!showPlans && productId && <div className='msgBanner centeredText'>
                {t.thankYouEmail} <strong>{session && session.user.email}</strong> {t.emailConfirmed}
            </div>}
            <div className='pageWrapper'>
                {!productId && <div className='slide vSlide'>
                    <p>
                        {t.thankYouEmail} <strong>{session && session.user.email}</strong> {t.emailConfirmed}
                    </p>
                    {!productId && <button className='btnBasic btnBig' onClick={() => window.location.href = "/"}>{t.continue}</button>}
                </div>}
                <div className='slide vSlide'>
                    {session && productId && !showPlans && <ConfirmProduct productId={productId} userID={session.user.id} email={session.user.email} trackers={userData ? userData.trackers : 1} setShowPlans={setShowPlans} language={language} />}
                    {showPlans && <Plans setShowPlans={setShowPlans} productId={productId} language={language} />}
                </div>
            </div>
        </>
    );
};

export default ConfirmedEmail;