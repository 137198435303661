import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import supabase from './SupabaseClient';
import NavBar from "./NavBar";
import FAQComponent from './ProductFAQ';
import Footer from "./Footer";

const SupportPage = ({ language }) => {
  const [formData, setFormData] = useState({ subject: "", description: "" });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState(null);

  const navigate = useNavigate(); // Initialize useNavigate for programmatic navigation

  useEffect(() => {
    const checkSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setSession(session);

      if (!session) {
        navigate('/signin');
      }
    };

    checkSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      setSession(session);

      if (!session) {
        navigate('/signin');
      }
    });

    return () => {
      subscription?.unsubscribe();
    };
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const updatedFormData = {
      subject: `${session?.user.email} send a support request: ${formData.subject}`,
      description: `**${session?.user.email} send a support request:**\n\n**${formData.subject}:**\n\n ${formData.description}`
    };

    try {
      const response = await fetch('/newSupportRequest', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedFormData),
      });

      if (response.ok) {
        setFormSubmitted(true);
      } else {
        console.error('Error submitting form');
      }

    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="support-page">
      <NavBar language={language} />

      <h1 className="redText centeredText capText noMarginP">There is no problem we can't solve...</h1>

      {!formSubmitted && <p className="centeredText">Drop us a line and we'll get back to you as soon as possible.</p>}

      {!formSubmitted ? (
        <form className="formElements formL" onSubmit={handleSubmit}>
          <input
            type="text"
            id="subject"
            placeholder="Subject"
            name="subject"
            className="mainInput"
            value={formData.subject}
            onChange={handleInputChange}
            required
          />

          <textarea
            id="description"
            placeholder="Describe your problem in few lines"
            name="description"
            className="mainInput mainTextarea"
            value={formData.description}
            onChange={handleInputChange}
            required
          />

          <button className='btnBasic' type="submit" disabled={loading}>
            {loading ? 'Sending...' : 'Send'}
          </button>
        </form>
      ) : (
        <p className='centeredText formL'>Thank you for your inquiry, we will get back to you in 24 to 48 hours.</p>
      )}
      <FAQComponent language={language} />
      <div className="footerHero marginT centeredV">
        <h2 className='redText capText noMarginP marginBB centeredText'>Ready to save money on your favorite items?</h2>
        <button className='btnBasic btnBig btnBlack' onClick={() => window.location.href = "/#trackersForm"}>
          Yeah, Let's do it!
        </button>
      </div>
      <Footer language={language} />
    </div>
  );
};

export default SupportPage;