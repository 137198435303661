import React from 'react';

const LogoBig = () => {
  const handleLogoClick = () => {
    window.location.href = '/'; 
    window.location.reload(); 
  };

  return (
    <a href="/" onClick={handleLogoClick}>      
      <svg width="139" height="60" viewBox="0 0 139 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M106.09 42.1091L114.726 33.6065L121.324 40.2046L101.479 60.0018L101.479 59.9466L101.479 60.0027L81.6299 40.2092L88.2279 33.6112L96.7436 42.1091V20.3086H106.09V42.1091Z" fill="#FF3D00"/>
        <path d="M11.3323 42.4183C9.07974 42.4183 7.34696 42.0891 6.13402 41.4306C4.92108 40.7722 4.07202 39.8191 3.58684 38.5715C3.13632 37.2893 2.91106 35.7471 2.91106 33.945V17.6223H0V12.0601H2.91106V2.91106H11.4883V12.0601H15.7509V17.6223H11.4883V33.1133C11.4883 33.9797 11.7309 34.6035 12.2161 34.9847C12.7359 35.3659 13.377 35.5565 14.1394 35.5565C14.59 35.5565 15.0058 35.5392 15.387 35.5045C15.7682 35.4352 16.1148 35.3832 16.4267 35.3486V42.0024C15.9762 42.0718 15.2657 42.1584 14.2954 42.2624C13.325 42.3663 12.3374 42.4183 11.3323 42.4183Z" fill="black"/>
        <path d="M20.1999 42.1064V0H28.6212V14.7113C29.7302 13.6023 30.8912 12.8052 32.1041 12.32C33.317 11.8002 34.6166 11.5403 36.0028 11.5403C37.2158 11.5403 38.2208 11.8522 39.0179 12.476C39.8496 13.0651 40.4734 13.8968 40.8893 14.9712C41.3051 16.0108 41.5131 17.2064 41.5131 18.558V42.1064H33.0918V19.7536C33.0918 19.0259 32.9705 18.4714 32.7279 18.0902C32.4853 17.709 32.0348 17.5183 31.3763 17.5183C31.0298 17.5183 30.6139 17.6223 30.1287 17.8302C29.6436 18.0035 29.1411 18.2634 28.6212 18.61V42.1064H20.1999Z" fill="black"/>
        <path d="M46.7004 42.1064V12.0601H55.1737V17.0505C56.144 15.3177 57.1144 14.0008 58.0848 13.0998C59.0551 12.1641 60.2334 11.6962 61.6196 11.6962C61.8969 11.6962 62.1394 11.7136 62.3474 11.7482C62.5553 11.7482 62.7459 11.7829 62.9192 11.8522V20.2735C62.538 20.1002 62.1048 19.9616 61.6196 19.8576C61.1691 19.719 60.6839 19.6497 60.1641 19.6497C59.1937 19.6497 58.2927 19.9096 57.461 20.4294C56.6292 20.9492 55.8668 21.6597 55.1737 22.5607V42.1064H46.7004Z" fill="black"/>
        <path d="M67.328 50.8396V44.9655C68.4023 44.9655 69.286 44.8962 69.9792 44.7576C70.7069 44.6189 71.2441 44.359 71.5906 43.9778C71.9718 43.5966 72.1625 43.0768 72.1625 42.4183C72.1625 41.8292 72.0585 41.1187 71.8506 40.287C71.6426 39.4206 71.3827 38.4503 71.0708 37.3759L64.0011 12.0601H71.9545L75.7493 29.4225L78.6084 12.0601H86.6138L79.7 42.8342C79.2495 44.9135 78.5391 46.525 77.5687 47.6686C76.5984 48.8469 75.4027 49.6613 73.9819 50.1118C72.5956 50.597 71.0188 50.8396 69.2514 50.8396H67.328Z" fill="black"/>
        <path d="M90.1612 42.1064V0H98.5825V42.1064H90.1612Z" fill="black"/>
        <path d="M104.426 42.1064V0H112.847V42.1064H104.426Z" fill="black"/>
        <path d="M128.204 42.6262C126.055 42.6262 124.184 42.2624 122.59 41.5346C121.03 40.8068 119.817 39.7152 118.951 38.2596C118.119 36.8041 117.703 34.9847 117.703 32.8014V21.3651C117.703 19.1818 118.119 17.3624 118.951 15.9069C119.817 14.4513 121.03 13.3597 122.59 12.6319C124.184 11.9042 126.055 11.5403 128.204 11.5403C130.353 11.5403 132.207 11.9042 133.766 12.6319C135.36 13.3597 136.591 14.4513 137.457 15.9069C138.323 17.3624 138.757 19.1818 138.757 21.3651V32.8014C138.757 34.9847 138.323 36.8041 137.457 38.2596C136.591 39.7152 135.36 40.8068 133.766 41.5346C132.207 42.2624 130.353 42.6262 128.204 42.6262ZM128.256 37.0121C128.81 37.0121 129.244 36.8734 129.556 36.5962C129.867 36.2843 130.075 35.8684 130.179 35.3486C130.318 34.7941 130.387 34.1356 130.387 33.3732V20.7933C130.387 20.0309 130.318 19.3897 130.179 18.8699C130.075 18.3154 129.867 17.8996 129.556 17.6223C129.244 17.3104 128.81 17.1545 128.256 17.1545C127.702 17.1545 127.268 17.3104 126.956 17.6223C126.645 17.8996 126.419 18.3154 126.281 18.8699C126.142 19.3897 126.073 20.0309 126.073 20.7933V33.3732C126.073 34.1356 126.142 34.7941 126.281 35.3486C126.419 35.8684 126.645 36.2843 126.956 36.5962C127.268 36.8734 127.702 37.0121 128.256 37.0121Z" fill="black"/>
      </svg>
    </a>
  );
}; 

export default LogoBig;