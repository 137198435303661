import React, { useState, useEffect } from 'react';

function ProductForm({ setProductData, setResetForm, isProductDataVisible, session, language }) {
  const [inputValue, setInputValue] = useState('');
  const [category, setCategory] = useState('');
  const [productId, setProductId] = useState('');
  const [productLink, setProductLink] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    setResetForm(() => resetForm);
  }, [setResetForm]);

  const resetForm = () => {
    setInputValue('');
    setCategory('');
    setProductId('');
    setProductLink('');
    setMessage('');
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    if (event.target.value.trim() !== '') {
      handleLink(event.target.value);
    } else {
      setMessage('');
      setCategory('');
      setProductId('');
      setProductLink('');
    }
  };

  const handleLink = (url) => {
    let parsedUrl;
    try {
      parsedUrl = new URL(url);
    } catch (e) {
      setMessage(
        <>{t.suggest} <a className="redLink" href="/stores?suggest=true" target='_blank'>{t.here}</a>.</>
      );
      setCategory('');
      return;
    }

    if (parsedUrl.hostname.includes('amazon')) {
      const productId = parsedUrl.pathname.split('/dp/')[1].split('/')[0];
      setCategory('Amazon');
      setProductId(productId);
      const amazonLink = `${parsedUrl.protocol}//${parsedUrl.hostname}/dp/${productId}`;
      setProductLink(amazonLink);
      setMessage('');
    } else if (parsedUrl.hostname.includes('asos')) {
      const pathSegments = parsedUrl.pathname.split('/');
      const prdIndex = pathSegments.indexOf('prd');
      if (prdIndex !== -1 && prdIndex < pathSegments.length - 1) {
        const productId = pathSegments[prdIndex + 1];
        const productIdWithoutQuery = productId.split('?')[0];
        const asosLink = `${parsedUrl.protocol}//${parsedUrl.hostname}${parsedUrl.pathname.split(productId)[0]}${productIdWithoutQuery}`;
        setCategory('Asos');
        setProductId(productIdWithoutQuery);
        setProductLink(asosLink);
        setMessage('');
      } else {
        setMessage('Invalid Asos link');
      }
    } else if (parsedUrl.hostname.includes('zalando')) {
      const pathParts = parsedUrl.pathname.split('/');
      const lastSegment = pathParts[pathParts.length - 1];
      const segments = lastSegment.split('-');
      const productId = segments.slice(-2).join('-');
      const productIdWithoutHtml = productId.split('.html')[0];
      const zalandoLink = parsedUrl.href;
      setCategory('Zalando');
      setProductId(productIdWithoutHtml);
      setProductLink(zalandoLink);
      setMessage('');
    } else if (parsedUrl.hostname.includes('argos')) {
      const pathname = parsedUrl.pathname;
      const productIndex = pathname.indexOf('/product/');
      const productIdSubstring = pathname.substring(productIndex + '/product/'.length);
      const productId = productIdSubstring.split('/')[0];
      const argosLink = parsedUrl.href;
      setCategory('Argos');
      setProductId(productId);
      setProductLink(argosLink);
      setMessage('');
    } else if (parsedUrl.hostname.includes('bananarepublic.gap')) {
      const searchParams = new URLSearchParams(parsedUrl.search);
      const productId = searchParams.get('pid');
      const BananaLink = parsedUrl.href;
      setCategory('BananaRepublic');
      setProductId(productId);
      setProductLink(BananaLink);
      setMessage('');
    } else if (parsedUrl.hostname.includes('oldnavy.gap')) {
      const params = new URLSearchParams(parsedUrl.search);
      const productId = params.get('pid');
      const OldnavyLink = parsedUrl.href;
      setCategory('Oldnavy');
      setProductId(productId);
      setProductLink(OldnavyLink);
      setMessage('');
    } else if (parsedUrl.hostname.includes('bedbathandbeyond')) {
      const productIdMatch = parsedUrl.pathname.match(/\/(\d+)\/product\.html/);
      const productId = productIdMatch ? productIdMatch[1] : null;
      const BBBLink = parsedUrl.href;
      setCategory('BedBathandBeyond');
      setProductId(productId);
      setProductLink(BBBLink);
      setMessage('');
    } else if (parsedUrl.hostname.includes('bestbuy')) {
      const productIdMatch = parsedUrl.href.match(/\/(\d+)\.p\?skuId=(\d+)/);
      const productId = productIdMatch ? productIdMatch[2] : null;
      const BestBuyLink = parsedUrl.href;
      setCategory('BestBuy');
      setProductId(productId);
      setProductLink(BestBuyLink);
      setMessage('');
    } /* else if (parsedUrl.hostname.includes('bol')) {
      const productIdMatch = parsedUrl.href.match(/\/(\d{16})\//);
      const productId = productIdMatch ? productIdMatch[1] : null;
      const BolLink = parsedUrl.href;
      setCategory('Bol');
      setProductId(productId);
      setProductLink(BolLink);
      setMessage('');
    } */ /* else if (parsedUrl.hostname.includes('dermstore')) {
      const productIdMatch = parsedUrl.pathname.match(/\/(\d+)\/?$/);
      const productId = productIdMatch ? productIdMatch[1] : null;

      const DermstoreLink = parsedUrl.href;
      setCategory('Dermstore');
      setProductId(productId);
      setProductLink(DermstoreLink);
      setMessage('');
    } */ else if (parsedUrl.hostname.includes('ebay')) {
      const productIdMatch = parsedUrl.pathname.match(/\/itm\/(\d+)/);
      const productId = productIdMatch ? productIdMatch[1] : null;

      const EbayLink = parsedUrl.href;
      setCategory('Ebay');
      setProductId(productId);
      setProductLink(EbayLink);
      setMessage('');
    } else if (parsedUrl.hostname.includes('gap') && !parsedUrl.hostname.includes('bananarepublic.gap') && !parsedUrl.hostname.includes('oldnavy.gap')) {
      const productIdMatch = parsedUrl.href.match(/pid=(\d+)/);
      const productId = productIdMatch ? productIdMatch[1] : null;

      const GapLink = parsedUrl.href;
      setCategory('Gap');
      setProductId(productId);
      setProductLink(GapLink);
      setMessage('');
    } else if (parsedUrl.hostname.includes('jcrew')) {
      const url = new URL(parsedUrl.href);
      const productId = url.searchParams.get('colorProductCode');

      const JcrewLink = parsedUrl.href;
      setCategory('Jcrew');
      setProductId(productId);
      setProductLink(JcrewLink);
      setMessage('');
    } else if (parsedUrl.hostname.includes('ikea')) {
      const regex = /\/p\/[^/]+-(\w+)\//;
      const match = parsedUrl.pathname.match(regex);

      let productId = '';
      if (match) {
        productId = match[1]; // The productId is captured in the first group
      }
      const IkeaLink = parsedUrl.href;
      setCategory('Ikea');
      setProductId(productId);
      setProductLink(IkeaLink);
      setMessage('');
    } /* else if (parsedUrl.hostname.includes('kohls')) {
      const productIdMatch = parsedUrl.pathname.match(/prd-\d+/);
      const productId = productIdMatch ? productIdMatch[0] : null;
      const KohlsLink = parsedUrl.href;
      setCategory('Kohls');
      setProductId(productId);
      setProductLink(KohlsLink);
      setMessage('');
    } */ else if (parsedUrl.hostname.includes('lidl')) {
      const path = parsedUrl.pathname;
      const match = path.match(/p(\d+)/);
      const productId = match ? `p${match[1]}` : null;

      const LidlLink = parsedUrl.href;
      setCategory('Lidl');
      setProductId(productId);
      setProductLink(LidlLink);
      setMessage('');
    } else if (parsedUrl.hostname.includes('macys')) {
      const params = new URLSearchParams(parsedUrl.search);
      const productId = params.get('ID');

      const MacysLink = parsedUrl.href;
      setCategory('Macys');
      setProductId(productId);
      setProductLink(MacysLink);
      setMessage('');
    } else if (parsedUrl.hostname.includes('marksandspencer')) {
      const path = parsedUrl.pathname;
      const match = path.match(/\/p\/(P\d+)\.html/);
      const productId = match ? match[1] : null;

      const MarksandSpencerLink = parsedUrl.href;
      setCategory('MarksAndSpencer');
      setProductId(productId);
      setProductLink(MarksandSpencerLink);
      setMessage('');
    } else if (parsedUrl.hostname.includes('microcenter')) {
      const path = parsedUrl.pathname;
      const match = path.match(/\/product\/(\d+)/);
      const productId = match ? match[1] : null;

      const MicrocenterLink = parsedUrl.href;
      setCategory('Microcenter');
      setProductId(productId);
      setProductLink(MicrocenterLink);
      setMessage('');
    } else if (parsedUrl.hostname.includes('next')) {
      let productId = parsedUrl.pathname.split('/style/')[1].split('/')[0];
      if (productId) {
        const NextLink = parsedUrl.href;
        setCategory('Next');
        setProductId(productId);
        setProductLink(NextLink);
        setMessage('');
      } else {
        setMessage(t.itemNotAvailable);
        setCategory('');
      }
    } else if (parsedUrl.hostname.includes('nike')) {
      const path = parsedUrl.pathname;
      const match = path.match(/\/t\/[a-zA-Z0-9-]+\/([a-zA-Z0-9-]+)/);
      const productId = match ? match[1] : null;

      const NikeLink = parsedUrl.href;
      setCategory('Nike');
      setProductId(productId);
      setProductLink(NikeLink);
      setMessage('');
    } else if (parsedUrl.hostname.includes('otto')) {
      const fragment = parsedUrl.hash;
      const match = fragment.match(/variationId=(\w+)/);
      const productId = match ? match[1] : null;

      const OttoLink = parsedUrl.href;
      setCategory('Otto');
      setProductId(productId);
      setProductLink(OttoLink);
      setMessage('');
    } else if (parsedUrl.hostname.includes('poshmark')) {
      // Split the pathname by '/' and take the last element
      const pathSegments = parsedUrl.pathname.split('/');
      // Ensure there's at least one segment (the potential productId) after splitting
      let potentialProductId = pathSegments.length > 1 ? pathSegments[pathSegments.length - 1] : '';

      // Further split the potentialProductId by '-' and take the last segment
      const idSegments = potentialProductId.split('-');
      // Extract the actual productId, ensuring it's the last segment and consists only of alphanumeric characters
      const productId = idSegments[idSegments.length - 1];

      const PoshmarkLink = parsedUrl.href;
      setCategory('Poshmark');
      setProductId(productId);
      setProductLink(PoshmarkLink);
      setMessage('');
    } else if (parsedUrl.hostname.includes('thereformation')) {
      const path = parsedUrl.pathname;
      const match = path.match(/\/products\/[^/]+\/(\d+[A-Z]+)\.html$/);
      const productId = match ? match[1] : null;

      const TheReformationLink = parsedUrl.href;
      setCategory('Reformation');
      setProductId(productId);
      setProductLink(TheReformationLink);
      setMessage('');
    } /* else if (parsedUrl.hostname.includes('samsclub')) {
      const path = parsedUrl.pathname;
      const match = path.match(/\/P(\d+)/);
      const productId = match ? 'P' + match[1] : null;
    
      const SamsclubLink = parsedUrl.href;      
      setCategory('Samsclub');
      setProductId(productId);
      setProductLink(SamsclubLink);
      setMessage('');
    } */ else if (parsedUrl.hostname.includes('shopbop')) {
      const match = parsedUrl.pathname.match(/\/vp\/v=1\/(\d+)\.htm/);
      const productId = match ? match[1] : null;
      const ShopBopLink = parsedUrl.href;
      setCategory('ShopBop');
      setProductId(productId);
      setProductLink(ShopBopLink);
      setMessage('');
    } else if (parsedUrl.hostname.includes('spartoo')) {
      const match = parsedUrl.pathname.match(/x(\d+)\.php/);
      const productId = match ? 'x' + match[1] : null;
      const SpartooLink = parsedUrl.href;
      setCategory('Spartoo');
      setProductId(productId);
      setProductLink(SpartooLink);
      setMessage('');
    } /* else if (parsedUrl.hostname.includes('target')) {
      const match = parsedUrl.pathname.match(/\/A-(\d+)/);
      const productId = match ? 'A-' + match[1] : null;
      const TargetLink = parsedUrl.href;
      setCategory('Target');
      setProductId(productId);
      setProductLink(TargetLink);
      setMessage('');
    } */ else if (parsedUrl.hostname.includes('ulta')) {
      const match = parsedUrl.search.match(/sku=(\d+)/);
      const productId = match ? match[1] : null;
      const UltaLink = parsedUrl.href;
      setCategory('Ulta');
      setProductId(productId);
      setProductLink(UltaLink);
      setMessage('');
    } else if (parsedUrl.hostname.includes('walmart')) {
      const productIdMatch = parsedUrl.pathname.match(/\/ip\/[^/]+\/(\d+)/);
      const productId = productIdMatch ? productIdMatch[1] : null;
      const WalmartLink = parsedUrl.href;
      setCategory('Walmart');
      setProductId(productId);
      setProductLink(WalmartLink);
      setMessage('');
    } else if (parsedUrl.hostname.includes('zappos')) {
      const matchProduct = parsedUrl.pathname.match(/\/product\/(\d+)\//);
      const matchColor = parsedUrl.pathname.match(/\/color\/(\d+)$/);
      const productId = matchProduct && matchColor ? matchProduct[1] + matchColor[1] : null;
      const ZapposLink = parsedUrl.href;
      setCategory('Zappos');
      setProductId(productId);
      setProductLink(ZapposLink);
      setMessage('');
    } else if (parsedUrl.hostname.includes('lululemon')) {
      // Use regex to extract 'prod' followed by digits and 'color' parameter followed by digits
      const productIdMatch = parsedUrl.href.match(/prod(\d+)\?color=(\d+)/);

      // If the match is successful, concatenate the two captured groups (prod digits and color digits)
      const productId = productIdMatch ? `prod${productIdMatch[1]}${productIdMatch[2]}` : null;

      const LululemonLink = parsedUrl.href;

      setCategory('Lululemon');
      setProductId(productId);
      setProductLink(LululemonLink);
      setMessage('');

    } else if (parsedUrl.hostname.includes('etsy')) {
      const productIdMatch = parsedUrl.pathname.match(/\/listing\/(\d+)/);
      const productId = productIdMatch ? productIdMatch[1] : null;
      const EtsyLink = parsedUrl.href;

      setCategory('Etsy');
      setProductId(productId);
      setProductLink(EtsyLink);
      setMessage('');

    } else if (parsedUrl.hostname.includes('homedepot')) {
      const productIdMatch = parsedUrl.pathname.match(/\/p\/[^/]+\/(\d+)$/);
      const productId = productIdMatch ? productIdMatch[1] : null;
      const HomedepotLink = parsedUrl.href;

      setCategory('Homedepot');
      setProductId(productId);
      setProductLink(HomedepotLink);
      setMessage('');
    } else if (parsedUrl.hostname.includes('madewell')) {
      const pathSegments = parsedUrl.pathname.split('/');
      const productNameSegment = pathSegments[pathSegments.length - 2];
      const urlParams = new URLSearchParams(parsedUrl.search);
      const ccodeValue = urlParams.get('ccode');

      const productId = `${productNameSegment}${ccodeValue}`;

      const madewellLink = parsedUrl.href;

      setCategory('Madewell');
      setProductId(productId);
      setProductLink(madewellLink);
      setMessage('');
    } else if (parsedUrl.hostname.includes('converse')) {
      const productIdMatch = parsedUrl.href.match(/styleNo=([^&]+)/);
      const productId = productIdMatch ? productIdMatch[1] : null;
      const ConverseLink = parsedUrl.href;

      setCategory('Converse');
      setProductId(productId);
      setProductLink(ConverseLink);
      setMessage('');
    } else {
      setMessage(t.notAvailable);
      setCategory('');
    }
  };

  /*   const handlePaste = async () => {
      try {
        const text = await navigator.clipboard.readText();
        setInputValue(text);
        if (text.trim() !== '') {
          handleLink(text);
        }
      } catch (err) {
        console.error('Failed to read clipboard contents: ', err);
      }
    }; */

  const handleSubmit = async () => {
    const payload = { category, productId, productLink };
    try {
      await fetch('/newProduct', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
      setProductData({ productId, category, productLink });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const texts = {
    en: {
      create: 'Create a thryllo',
      stores: 'See all the stores you can track',
      store: 'Store:',
      notAvailable: 'This store is not available yet 🙁...',
      itemNotAvailable: 'This is not available to track 🙁...',
      suggest: 'This store is not available yet 🙁... You can suggest it to us',
      here: 'here',
      placeholderTracker: 'Paste the link of the item you’re interested in tracking.',
    },
    fr: {
      create: 'Créer un thryllo',
      stores: 'Voir toutes les boutiques que vous pouvez suivre',
      store: 'Magasin :',
      notAvailable: 'Ce magasin n\'est pas encore disponible 🙁...',
      itemNotAvailable: 'Cet article n\'est pas disponible pour le suivi 🙁...',
      suggest: 'Ce magasin n\'est pas encore disponible 🙁... Vous pouvez nous le suggérer',
      here: 'ici',
      placeholderTracker: 'Collez le lien de l\'article que vous souhaitez suivre.',
    },
    es: {
      create: 'Crear un thryllo',
      stores: 'Ver todas las tiendas que puedes rastrear',
      store: 'Tienda:',
      notAvailable: 'Esta tienda aún no está disponible 🙁...',
      itemNotAvailable: 'Este artículo no está disponible para rastrear 🙁...',
      suggest: 'Esta tienda aún no está disponible 🙁... Puedes sugerirla',
      here: 'aquí',
      placeholderTracker: 'Pega el enlace del artículo que te interesa rastrear.',
    },
    de: {
      create: 'Einen thryllo erstellen',
      stores: 'Sehen Sie alle Geschäfte, die Sie verfolgen können',
      store: 'Geschäft:',
      notAvailable: 'Dieses Geschäft ist noch nicht verfügbar 🙁...',
      itemNotAvailable: 'Dieses Produkt kann nicht verfolgt werden 🙁...',
      suggest: 'Dieses Geschäft ist noch nicht verfügbar 🙁... Sie können es uns vorschlagen',
      here: 'hier',
      placeholderTracker: 'Fügen Sie den Link des Artikels ein, den Sie verfolgen möchten.',
    },
    lt: {
      create: 'Sukurti thryllo',
      stores: 'Peržiūrėkite visas parduotuves, kurias galite stebėti',
      store: 'Parduotuvė:',
      notAvailable: 'Ši parduotuvė dar nepasiekiama 🙁...',
      itemNotAvailable: 'Šis produktas dar negali būti stebimas 🙁...',
      suggest: 'Ši parduotuvė dar nepasiekiama 🙁... Galite ją mums pasiūlyti',
      here: 'čia',
      placeholderTracker: 'Įklijuokite nuorodą į prekę, kurią norite stebėti.',
    }
  };

  const t = texts[language];

  return (
    <div id="trackersForm" className={`${!session && !isProductDataVisible ? 'formWrapper' : ''}`}>
      <div className={session ? 'hFlexFit marginS marginT' : 'hFlexFit marginS'}>
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder={t.placeholderTracker}
          className='mainInput'
          required
          autoFocus
        />
        <button className='btnBasic btnBig' disabled={isProductDataVisible || !!message || !inputValue} onClick={handleSubmit}>{t.create}</button>
      </div>
      <div>
        {!category && !message && <div className='marginB pdng'><a className='baseLink' href='/stores' target='_blank'><strong>{t.stores}</strong></a></div>}
        {category && <div className='formMessage'><p className='capText'><strong>{t.store} {category}</strong></p></div>}
        {message && <div className='formMessage'><p><strong>{message}</strong></p></div>}
      </div>
    </div>
  );
}

export default ProductForm;