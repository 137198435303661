import React from 'react';

const Tooltip = () => {
  return (
        <svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect className='tooltipCircle' y="8" width="15" height="15" rx="7.5" fill="#D9D9D9"/>
            <rect x="6.5" y="10.5" width="2" height="2" fill="white"/>
            <rect x="6.5" y="14.5" width="2" height="6" fill="white"/>
        </svg>
    );
}; 

export default Tooltip;