import React from 'react';
import Walmart from './icons/Walmart';
/* import Target from './icons/Target'; */
import Amazon from './icons/Amazon';
import Nike from './icons/Nike';
import Ulta from './icons/Ulta';
import Asos from './icons/Asos';
import Argos from './icons/Argos';
import BBB from './icons/BedBathBeyond';
import BestBuy from './icons/BestBuy';
/* import Bol from './icons/Bol'; */
/* import Dermstore from './icons/Dermstore'; */
import Next from './icons/Next';
import Gap from './icons/Gap';
import Lidl from './icons/Lidl';
import Ikea from './icons/Ikea';
import Jcrew from './icons/Jcrew';
/* import Kohls from './icons/Kohls'; */
import Macys from './icons/Macys';
import MandS from './icons/MandS';
import Microcenter from './icons/Microcenter';
import Oldnavy from './icons/Oldnavy';
/* import Samsclub from './icons/Samsclub'; */
import Spartoo from './icons/Spartoo';
import Shopbop from './icons/Shopbop';
import Zalando from './icons/Zalando';
import Zappos from './icons/Zappos';
import Otto from './icons/Otto';
import BananaRepublic from './icons/BananaRepublic';
import Poshmark from './icons/Poshmark';
import Reformation from './icons/Reformation';
import Ebay from './icons/Ebay';
import Etsy from './icons/Etsy';
/* import Lululemon from './icons/Lululemon'; */
import HomeDepot from './icons/HomeDepot';
import Madewell from './icons/Madewell';
import Jordan from './icons/Jordan';
import Converse from './icons/Converse';

const logos = [
  <Amazon />, <Walmart />, /* <Target />, */ <Nike />, <BestBuy />, <Ebay />,
  <HomeDepot />, <Etsy />, <Jordan />, <Converse />, /* <Samsclub />, */ <Ulta />, /* <Lululemon />, */ /* <Kohls />, */
  <Macys />, <Ikea />, <Oldnavy />, <BananaRepublic />, <Gap />,
  <Poshmark />, <Zappos />, /* <Dermstore />,  */<Reformation />, <Microcenter />,
  <Jcrew />, <Madewell />, <Shopbop />, <Asos />, <BBB />, <Zalando />, <Argos />, <Next />,
  <Lidl />, <MandS />, <Otto />, /* <Bol />, */ <Spartoo />
];

const LogoCarousel = () => {
  return (
    <div className='carouselContainer'>
      <div className='carouselContent'>
        {logos.concat(logos).map((Logo, index) => (
          <div key={index} className='logoWrapper'>
            {Logo}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogoCarousel;