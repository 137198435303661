import { createClient } from '@supabase/supabase-js';

let supabase = null;

const getSupabaseClient = async () => {
  if (!supabase) {
    const response = await fetch('/getSupabaseKey', {
      method: 'GET', 
      headers: {
          'Content-Type': 'application/json',
      },
  });
    const { key: supabaseKey, url: supabaseUrl } = await response.json();

    supabase = createClient(supabaseUrl, supabaseKey);
  }
  return supabase;
};

await getSupabaseClient();

export default supabase;
