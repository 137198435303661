import React, { useState } from 'react';

const HeaderChecker = () => {
  const [origin, setOrigin] = useState('');
  const [referer, setReferer] = useState('');
  const [UA, setUa] = useState('');
  const [error, setError] = useState('');

  const checkHeaders = async () => {
    try {
      const response = await fetch('/getOrigin', {
        method: 'GET',
        headers: {
          'Origin': window.location.origin, // Include the current window origin
        },
      });
      if (response.ok) {
        const data = await response.json(); // Parse the JSON response
        
        // Update the state with the origin and referer values
        setOrigin(data.origin);
        setReferer(data.referer);
        setUa(data.ua);
      } else {
        setError('Error fetching headers');
      }
    } catch (err) {
      setError('An error occurred: ' + err.message);
    }
  };

  return (
    <div>
      <h1>Check Origin and Referer Headers</h1>
      <button onClick={checkHeaders}>Check Headers</button>

      {origin && referer && (
        <div>
          <h2>Headers Found:</h2>
          <p><strong>Origin:</strong> {origin}</p>
          <p><strong>Referer:</strong> {referer}</p>
          <p><strong>Referer:</strong> {UA}</p>
        </div>
      )}

      {error && (
        <div>
          <h2>Error</h2>
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default HeaderChecker;
